const announcementBarViewModule = () => import(/* webpackChunkName: 'components/announcementBarView' */'../../components/announcementBar/announcementBar');
const headerModule = () => import(/* webpackChunkName: 'components/header' */'../../components/header/header');
const footerModule = () => import(/* webpackChunkName: 'components/footer' */'../../components/footer/footer');
const leadGenViewModule = () => import(/* webpackChunkName: 'components/leadGen' */'../../components/leadGen/leadGen');
const newsletterViewModule = () => import(/* webpackChunkName: 'components/newsletterView' */'../../components/newsletter/newsletter');
const geolocModule = () => import(/* webpackChunkName: 'utils/geoloc' */'../../utils/js/geoloc');
const tokyWokyModule = () => import(/* webpackChunkName: 'components/tokyWoky' */'../../components/tokyWoky/tokyWoky');

import Form from '../../components/form/form';
window.Form = _.extend(window.Form, Form);

$(() => {
  geolocModule().then(({geoloc}) => {
    window.geoloc = geoloc;
  });

  announcementBarViewModule().then(({AnnouncementBarView}) => {
    new AnnouncementBarView();
  });

  headerModule().then(({Header}) => {
    new Header();
  });

  // Force page scroll position to top after page reload
  history.scrollRestoration = 'manual';

  leadGenViewModule().then(({LeadGenView}) => {
    new LeadGenView();
  });

  footerModule();

  newsletterViewModule().then(({NewsletterView}) => {
    new NewsletterView();
  });

  tokyWokyModule().then(({TokyWoky}) => {
    new TokyWoky();
  });

  window.addEventListener('load', () => {
    document.querySelector('body').classList.remove('criticals');
  });
});